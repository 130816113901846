import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import PageSections from "../components/pageSections/index.js";

// markup
const ContactUsPage = ({ data, pageContext: { originalPath, contentful_id = null }, uri }) => {
  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }

  const restaurant = (data?.restaurant && (data?.restaurant.contentful_id === contentful_id)) ? data.restaurant : null;
  const seoTitle = restaurant && restaurant.seoTitle ? restaurant.seoTitle : pageNode.title;
  const seoDescription = restaurant && restaurant.seoDescription ? restaurant.seoDescription : pageNode.location;
  const seoH1Title = restaurant && restaurant.seoH1Title ? restaurant.seoH1Title : pageNode.headerTitle;
  
  let url = '';
	const baseUrl = typeof window !== 'undefined' ? window.location.origin : 'https://www.ajisen.com.hk/';
  url = `${baseUrl}${uri}`;

  return (
    <Layout
      title={seoTitle}
      description={seoDescription}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      brands={data.brands.nodes[0].brands}
      globalNav={data.globalNav?.nodes[0]}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      headerTitle={seoH1Title}
      headerBackgroundImage={pageNode.headerBackgroundImage}
      settings={data.settings?.nodes[0]}
      hasDeco={false}
    >
      <PageSections sections={pageNode.sections} />
      {restaurant && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Restaurant",
              name: restaurant.name,
              ...(restaurant.image?.url && { image: [restaurant.image.url] }),
              ...(restaurant.address?.address && {
                address: {
                  "@type": "PostalAddress",
                  streetAddress: restaurant.address.address,
                  addressRegion: "Hong Kong",
                  addressCountry: "HK",
                },
              }),
              ...(restaurant.mapLocation?.lat && restaurant.mapLocation?.lon && {
                geo: {
                  "@type": "GeoCoordinates",
                  latitude: restaurant.mapLocation.lat,
                  longitude: restaurant.mapLocation.lon,
                },
              }),
              ...(restaurant.reviews && restaurant.reviews.length > 0 && {
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingCount: restaurant.reviews.length,
                  ratingValue: "5.0"
                },
                review: restaurant.reviews.map(review => ({
                  "@type": "Review",
                  reviewRating: {
                    "@type": "Rating",
                    ratingValue: "5.0",
                    bestRating: "5.0",
                  },
                  reviewBody: review.text,
                })),
              }),
              telephone: restaurant.contact,
              servesCuisine: ["Japanese", "Ramen"],
              ...(restaurant.description?.description && { description: restaurant.description.description }),
              url: url,
              menu: url
            }),
          }}
        />
      )}
    </Layout>
  );
};

export default ContactUsPage;

export const data = graphql`
  query contactUsPageQuery($hrefLang: String!, $contentful_id: String) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    brands: allContentfulPageSectionBrands(
      filter: {
        node_locale: { eq: $hrefLang }
        contentful_id: { eq: "2kjRmkm5mviLHU4RYXdrP6" }
      }
    ) {
      nodes {
        brands {
          name
          slug
        }
      }
    }
    restaurant: contentfulRestaurant(
      node_locale: { eq: $hrefLang }, contentful_id: { eq: $contentful_id }
    ) {
      contentful_id
      seoTitle
      seoDescription
      seoH1Title
      name
      slug
      location
      address {
        address
      }
      description {
        description
      }
      image {
        url
      }
      mapLocation {
        lon
        lat
      }
      contact
      reviews {
        text
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulPage(
      filter: {
        contentfulid: { eq: "contact-us" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...Page
      }
    }
  }
`;
